import React from 'react'
import './App.css';
import Layout from './Components/Layout';

const App = () => {
  return (
    <div>
      <Layout />
    </div>
  )
}

export default App